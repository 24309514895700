<template>
    <div>
        <CCard>
            <CDataTable
               :items="referral"
               :fields="fields"
               fixed
               hover
               striped
               bordered
               pagination
               :itemsPerPage="limit"
               class="table-members table-referral"
            >
               <template ##="{item, index}">
                    <td :class="(index + (activePage-1)*limit) > 0 ? '' : 'first'">{{(index + (activePage-1)*limit) > 0 ? index + (activePage-1)*limit : ""}}</td>
                </template>
                <template #num_of_level_1_affiliates="{item, index}">
                    <td  :class="(index + (activePage-1)*limit) > 0 ? '' : 'first'">{{(index + (activePage-1)*limit) > 0 ? item.ext_client_id : item.num_of_level_1_affiliates}}</td>
                </template>
                <template #num_of_level_2_affiliates="{item,index}">
                    <td  :class="(index + (activePage-1)*limit) > 0 ? '' : 'first'">{{item.num_of_level_2_affiliates}}</td>
                </template>
                <template #num_of_level_3_affiliates="{item,index}">
                    <td  :class="(index + (activePage-1)*limit) > 0 ? '' : 'first'">{{item.num_of_level_3_affiliates}}</td>
                </template>
                <template #num_of_level_4_affiliates="{item,index}">
                    <td  :class="(index + (activePage-1)*limit) > 0 ? '' : 'first'">{{item.num_of_level_4_affiliates}}</td>
                </template>
                <template #total="{item,index}">
                    <td  :class="(index + (activePage-1)*limit) > 0 ? '' : 'first'">{{item.total}}</td>
                </template>
            </CDataTable>
        </CCard>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ListReferral',
  data() {
    return {
      fields: [
        '#',
        {
          key: 'num_of_level_1_affiliates',
          label: this.$t('MEMBER_REFFERAL_TABLE_COMPONENT_COL_1'),
        },
        {
          key: 'num_of_level_2_affiliates',
          label: this.$t('MEMBER_REFFERAL_TABLE_COMPONENT_COL_2'),
        },
        {
          key: 'num_of_level_3_affiliates',
          label: this.$t('MEMBER_REFFERAL_TABLE_COMPONENT_COL_3'),
        },
        {
          key: 'num_of_level_4_affiliates',
          label: this.$t('MEMBER_REFFERAL_TABLE_COMPONENT_COL_4'),
        },
        {
          key: 'total',
          label: this.$t('MEMBER_REFFERAL_TABLE_COMPONENT_COL_TOTAL'),
        },
      ],
    };
  },
  props: {
    referral: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 999,
    },
  },
  methods: {},
};
</script>
<style lang="scss">
    .table-members {
        tbody {
            td {
                color: #4d5666;
            }
        }
    }
    .table-referral{
        tbody  tr td.first{
            background: #586591;
            color: #fff;
            text-decoration: none;
            font-weight: bold;
        }
        td:nth-child(2){
            color: #5F55E7;
            text-decoration: underline;
        }

    }
</style>