<template>
  <div class="referral-container">
    <CCard>
      <CCardBody>
        <CRow>
          <CCol md="10">
            <CLink class="btn-back" @click="back">
              <CIcon name="cil-arrow-left" />
              {{$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_LINK_GO_BACK')}}
            </CLink>
          </CCol>
          <CCol md="2">
            <button
              v-if="isShownviewTreeChart"
              class="btn btn-view-tree-chart"
              @click="viewTreeChart()"
            >
              <i class="fas fa-tree"></i>
            </button>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="2">
            <h4>{{$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_LABEL_REFERRED_BY')}}</h4>
          </CCol>
          <CCol class="email">
            <p>{{referrer_email}}</p>
            <p>{{email}}</p>
          </CCol>
        </CRow>
        <CRow class="mb-30" v-if="!isAllowedUpdateMember">
          <CCol md="2">
            <label
              class="references-label"
            >{{$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_LABEL_MAX_REFERENCES')}}</label>
          </CCol>
          <CCol md="10">
            <p class="read-only">{{maxReferences}}</p>
          </CCol>
        </CRow>
        <CRow class="group-max-references" v-if="isAllowedUpdateMember">
          <CCol>
            <ValidationProvider
              name="MEMBER_REFERRAL_STRUCTURE_COMPONENT_FIELD_NAME_VALIDATE_MAX_REFERENCES"
              v-slot="{ errors }"
              :rules="maxReferencesRules"
              ref="maxReferences"
            >
              <CInput
                :label="$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_LABEL_MAX_REFERENCES')"
                type="text"
                :horizontal="{ label: 'col-sm-2', input: 'col-sm-10'}"
                v-model="maxReferences"
                @focus="handleFocusMaxReferences"
                @blur="maxReferences!=''?isMaxReferencesFocus=true:cancelMaxReferences()"
              />
              <CRow v-if="errMsg || errors[0]">
                <CCol>
                  <p class="error-msg mt-1">{{errors[0] || errMsg}}</p>
                </CCol>
              </CRow>
            </ValidationProvider>
            <div
              v-if="isMaxReferencesFocus && isAllowedUpdateMember"
              class="float-buttons-container"
            >
              <div class="referrer-actions">
                <CButton class="referrer-submit" @click="submitMaxReferences">
                  <CIcon name="cil-check-alt" />
                </CButton>
                <CButton @click="cancelMaxReferences">
                  <CIcon name="cil-x" />
                </CButton>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <ListReferral
              :limit="limit"
              :activePage="activePage"
              :referral="referral"
              :total="total"
            />
          </CCol>
          <CCol md="12">
            <StakingPagination
              :limit="limit"
              :total="total"
              :activePage="activePage"
              @changePage="onChangePage"
              @changePageSize="onChangePageSize"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import ListReferral from '@/components/Member/ListReferral';
import StakingPagination from '@/components/StakingPagination';
import { env } from '@/constants';

export default {
  name: 'Referral',
  components: {
    ListReferral,
    StakingPagination,
  },
  data() {
    return {
      isShownviewTreeChart: env.app.isShownviewTreeChart,
      memberId: null,
      referral: [],
      email: null,
      referrer_email: null,
      totalReferences: 0,
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      pageInput: 1,
      putActivePage: 1,
      maxReferences: 0,
      originalMaxReferences: 0,
      isAllowedUpdateMember: false,
      isMaxReferencesFocus: false,
      errMsg: '',
    };
  },
  async mounted() {
    this.memberId = this.$route.params.memberId;
    this.checkPermissions();
    this.getReferral();
    this.getMaxReferences();
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
    maxReferencesRules() {
      if (this.maxReferences == 0) {
        return 'min_value:0|integer';
      }

      return `min_value:${this.totalReferences}|integer`;
    },
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(items => {
          if (items.name === 'MEMBERSHIP_UPDATE_MEMBER') {
            this.isAllowedUpdateMember = true;
          }
        });
      }
    },
    viewTreeChart() {
      this.$router.push(`/admin/members/${this.memberId}/tree-chart`);
    },
    async getReferral() {
      try {
        const result = await this.$http.get(endpoints.getReferral(this.memberId), {});
        this.total = result.data.affiliate.length;
        this.referral = this.pagination(result.data.affiliate);

        const firstRow = this.referral[0];
        this.totalReferences = firstRow ? firstRow.num_of_level_1_affiliates : 0;
        this.email = result.data.email;
        this.referrer_email = result.data.referrer_email;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });

        return null;
      }
    },
    async onChangePage(page) {
      this.activePage = page;
      this.getReferral();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;
      this.getReferral();
    },
    back() {
      this.$router.push('/admin/members');
    },
    pagination(value) {
      const pagination = [];
      for (let i = this.offset; i < value.length; i++) {
        if (i < this.limit + this.offset) {
          pagination.push(value[i]);
        }
      }
      return pagination;
    },
    async getMaxReferences() {
      try {
        const res = await this.$http.get(endpoints.getMaxReferences(this.memberId));
        this.maxReferences = this.originalMaxReferences = res.data.max_references;
        const isDeactived = res.data.deleted_flg;
        if (isDeactived) {
          this.isAllowedUpdateMember = false;
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async submitMaxReferences() {
      const isValid = await this.$refs.maxReferences.validate();
      if (!isValid.valid) {
        return;
      }

      try {
        const params = { max_references: this.maxReferences };
        const res = await this.$http.put(endpoints.updateMaxReferences(this.memberId), params);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('MEMBER_REFERRAL_STRUCTURE_COMPONENT_NOTIFY_SUCCESS_MESSAGE'),
        });

        this.originalMaxReferences = res.data.max_references;
        this.isMaxReferencesFocus = false;
        await this.$refs.maxReferences.validate();

        this.errMsg = '';
      } catch (err) {
        this.errMsg = responseHelper.getErrorMessage(err);
      }
    },
    handleFocusMaxReferences() {
      this.isMaxReferencesFocus = true;
    },
    cancelMaxReferences() {
      this.maxReferences = this.originalMaxReferences;
      this.isMaxReferencesFocus = false;
      this.errMsg = '';
    },
  },
};
</script>
<style lang="scss">
@mixin label {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}
.referral-container {
  padding: 0 10px;
  .btn-back {
    color: #695fe5;
    display: inherit;
    margin-bottom: 40px;
  }
  .btn-back svg {
    margin-right: 10px;
  }

  .btn-view-tree-chart {
    float: right;
  }

  h4 {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
  }
  .email {
    margin-bottom: 10px;
    p:first-child {
      color: #6c65e4;
    }
    p:last-child {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .group-max-references {
    position: relative;
    margin-bottom: 50px;
    display: flex;
    flex: 0 0 100%;

    .float-buttons-container {
      position: relative;
      .referrer-actions {
        position: absolute;
        left: calc(41.6667% - 80px);
        top: 5px;
        z-index: 99;
        button {
          width: 35px;
          height: 35px;
          background: #f5f6f8;
          color: #3a4a7f;
          box-shadow: -2px 2px 5px -2px #00000080;
          padding: 0;
          &.referrer-submit {
            margin-right: 10px;
          }
        }
      }
    }
    .error-msg {
      font-size: 14px;
      font-weight: 300;
      color: #ff0000;
      line-height: 20px;
      width: 25%;
      margin-left: 16.6667%;
      margin-bottom: 10px;
      padding: 0;
    }
    span {
      width: 100%;
      .form-group {
        display: flex;
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 0;
      }
    }
    label {
      @include label;
    }
    .col-sm-10 {
      padding-left: 10px;
      input {
        width: 30%;
      }
    }
  }
  .mb-30 {
    margin-bottom: 30px;
    label {
      @include label;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #3a4a7f;
    }
  }
}
</style>
