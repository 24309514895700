var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CDataTable',{staticClass:"table-members table-referral",attrs:{"items":_vm.referral,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","pagination":"","itemsPerPage":_vm.limit},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{class:(index + (_vm.activePage-1)*_vm.limit) > 0 ? '' : 'first'},[_vm._v(_vm._s((index + (_vm.activePage-1)*_vm.limit) > 0 ? index + (_vm.activePage-1)*_vm.limit : ""))])]}},{key:"num_of_level_1_affiliates",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{class:(index + (_vm.activePage-1)*_vm.limit) > 0 ? '' : 'first'},[_vm._v(_vm._s((index + (_vm.activePage-1)*_vm.limit) > 0 ? item.ext_client_id : item.num_of_level_1_affiliates))])]}},{key:"num_of_level_2_affiliates",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{class:(index + (_vm.activePage-1)*_vm.limit) > 0 ? '' : 'first'},[_vm._v(_vm._s(item.num_of_level_2_affiliates))])]}},{key:"num_of_level_3_affiliates",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{class:(index + (_vm.activePage-1)*_vm.limit) > 0 ? '' : 'first'},[_vm._v(_vm._s(item.num_of_level_3_affiliates))])]}},{key:"num_of_level_4_affiliates",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{class:(index + (_vm.activePage-1)*_vm.limit) > 0 ? '' : 'first'},[_vm._v(_vm._s(item.num_of_level_4_affiliates))])]}},{key:"total",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{class:(index + (_vm.activePage-1)*_vm.limit) > 0 ? '' : 'first'},[_vm._v(_vm._s(item.total))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }